import { createSSRApp } from 'vue'
import dayjs from 'dayjs'
import uviewPlus, { setConfig } from 'uview-plus'
import App from './App.vue'
import pinia from './stores'
// import 'virtual:windi.css'
import { formatDate, $imgPath } from './utils'
import Empty from '@/components/empty'
import EmptyWrap from '@/components/empty-wrap'
import router from '@/router'

setConfig({
  // 修改$u.config对象的属性
  config: {
    // 修改默认单位为rpx，相当于执行 uni.$u.config.unit = 'rpx'
    unit: 'rpx',
  },
  // 修改$u.props对象的属性
  props: {
    // 修改radio组件的size参数的默认值，相当于执行 uni.$u.props.radio.size = 30
    radio: {
      size: 15,
    },
    // 其他组件属性配置
    // ......
  },
})

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $dayjs: dayjs
    $router: router
    $formatDate: formatDate
    $imgPath: $imgPath
  }
}

export function createApp() {
  const app = createSSRApp(App)
  app.config.globalProperties.$dayjs = dayjs
  app.config.globalProperties.$formatDate = formatDate
  app.config.globalProperties.$router = router
  app.config.globalProperties.$imgPath = $imgPath
  app.component('Empty', Empty)
  app.component('EmptyWrap', EmptyWrap)
  app.use(pinia)
  app.use(uviewPlus)

  return {
    app,
  }
}
