// 主包
const mainPackage = {
  index: '/pages/index/index',
  invite: '/pages/index/invite',
  market: '/pages/market/index',
  homeSearch: '/pages/index/home-search',
  login: '/pages/common/login',
  register: '/pages/common/register',
  loginInput: '/pages/common/login-input',
  brandLogin: '/pages/common/brand-login',
  messageCenter: '/pages/my/message/message-center',
  messageList: '/pages/my/message/message-list',
  messageDetails: '/pages/my/message/message-details',
  messageCenterAdmin: '/pages/admin/message/message-center',
  messageListAdmin: '/pages/admin/message/message-list',
  messageDetailsAdmin: '/pages/admin/message/message-details',
  settings: '/pages/my/settings',
  withdraw: '/pages/my/withdraw',
  withdrawDetails: '/pages/my/withdraw-details',
  withdrawSucc: '/pages/my/withdraw-succ',
  profile: '/pages/my/profile',
  level: '/pages/my/level',
  favorite: '/pages/my/favorite',
  editNickname: '/pages/my/edit-nickname',
  tutorial: '/pages/index/tutorial',
  taskDetails: '/pages/market/task-details',
  admin: '/pages/admin/index',
  introduce: '/pages/admin/introduce',
  dataInfo: '/pages/admin/data-info',
  check: '/pages/admin/check',
  agreement: '/pages/common/agreement',
  authentication: '/pages/common/authentication',
  submitTask: '/pages/order/submit-task',
  brandDetails: '/pages/market/brand-detail',
}

// // 分包
const prefix = '/package-sub'

const subPackage = {
  subIndex: `${prefix}/pages/my-reward/index`,
}

const pages = {
  ...mainPackage,
  ...subPackage,
}

export default pages
