import { defineStore } from 'pinia'
import { RootState } from './types'

export const useCommonStore = defineStore('common', {
  state: (): RootState => ({
    systemInfo: {
      safeArea: {},
      safeAreaInsets: {},
      screenWidth: '',
      screenHeight: '',
      statusBarHeight: '',
      windowWidth: '',
      windowHeight: '',
      platform: '',
    },
    isIOS: false, // 是否ios
    isAndroid: false, //
    companyInfo: {}, // 公司信息
    isEn: false, // 是否英文
    courseStatus: '', // 课表状态筛选
    classType: '', // 课表类型筛选
    subjectId: '', // 栏目
  }),
  getters: {
    safeBottom() {
      return this.isAndroid
        ? '20px'
        : `${this.systemInfo.safeAreaInsets.bottom}px`
    },
  },
  actions: {
    getSystemInfo() {
      const systemInfo = uni.getSystemInfoSync()
      this.systemInfo = systemInfo

      console.log(systemInfo.platform)
      this.isIOS = systemInfo.platform === 'ios'
      this.isAndroid = systemInfo.platform === 'android'
    },
  },
})
