// 创建人：Michael
// 创建时间：2020/08/19
// 各种常用方法封装,一般会复用到才放这个文件
import dayjs from 'dayjs'
import { getStorage } from './storage'
import router from '@/router'

// toast提示封装
export function $toast(
  title: string,
  duration = 1500,
  icon: UniApp.ShowToastOptions.icon = 'none',
) {
  return uni.showToast({
    title,
    duration,
    icon,
  })
}

// modal提示封装
export function $modal(title: string, confirmText = ''): Promise<boolean> {
  return new Promise((resolve) => {
    const isEn = getStorage('isEn')
    uni.showModal({
      title,
      showCancel: false,
      confirmColor: '#ff270a',
      confirmText: confirmText ? confirmText : isEn ? 'Confirm' : '确定',
      success(res) {
        if (res.confirm) {
          return resolve(true)
        }
        resolve(false)
      },
    })
  })
}

// // modal提示封装
export function $showModal(
  title: string,
  options = { confirmText: '', showCancel: false },
): Promise<boolean> {
  const { showCancel, confirmText } = options
  return new Promise((resolve) => {
    const isEn = getStorage('isEn')
    uni.showModal({
      title,
      showCancel,
      confirmText: confirmText ? confirmText : isEn ? 'Confirm' : '确定',
      confirmColor: '#ff270a',
      success(res) {
        if (res.confirm) {
          return resolve(true)
        }
        resolve(false)
      },
    })
  })
}

// loading
export function $loading(title = '加载中...') {
  return uni.showLoading({
    title,
  })
}

// export function $hideLoading() {
//   wx.hideLoading({
//     noConflict: true,
//   })
// }

export function formatDate(date: string) {
  return dayjs(date).format('YYYY-MM-DD HH:mm')
}

// 引入本地静态图片，缩短图片地址路径写法
export function $getImgPath({ path = '', format = 'png', isSubPack = false }) {
  return isSubPack
    ? `/package-sub/static/images/${path}.${format}`
    : `/static/images/${path}.${format}`
}

// 引入本地静态图片，缩短图片地址路径写法
export function $imgPath(path: string, option = {}) {
  const defaultOption = { format: 'png', isSub: false }
  const o = { ...defaultOption, ...option }
  const { format, isSub } = o
  return isSub
    ? `/package-sub/static/images/${path}.${format}`
    : `/static/images/${path}.${format}`
}

// setTabBarItem方法只能在tabbar页面调用
export function setTabBarItem(home: string, schedule: string, my: string) {
  uni.setTabBarItem({
    index: 0,
    text: home,
  })
  uni.setTabBarItem({
    index: 1,
    text: schedule,
  })
  uni.setTabBarItem({
    index: 2,
    text: my,
  })
}

export function getQueryVariable(
  url: string,
  variable: string,
): string | boolean {
  const paramsUrl = url.split('?')[1]
  const vars = paramsUrl.split('&')

  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=')

    if (pair[0] == variable) {
      return pair[1]
    }
  }

  return false
}

// 深拷贝，FIXME
export function cloneDeep(data: unknown) {
  return JSON.parse(JSON.stringify(data))
}

// 检测是否包含emoji
export function checkHasEmoji(message: string) {
  // emoji 表情正则
  const emojiReg =
    /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g
  return emojiReg.test(message)
}

// 校验手机号
export function isPhone(phone: sring) {
  return /^1[3456789]\d{9}$/.test(phone)
}

// 向字符串指定位置传入内容
export function insertStr(source: string, start: number, insertStr: string) {
  return source.slice(0, start) + insertStr + source.slice(start)
}

// 判断是否H5微信环境，true为微信浏览器
export function isWxBrowser() {
  const ua = navigator.userAgent.toLowerCase()
  return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false
}

// h5下载文件
export function h5DownloadFile(res, fileName) {
  const aLink = document.createElement('a')
  const blob = new Blob([res])
  const fileNames = fileName.replace(/\"/g, '')
  aLink.style.display = 'none'
  aLink.href = URL.createObjectURL(blob)
  aLink.setAttribute('download', fileNames) // 设置下载文件名称
  document.body.appendChild(aLink)
  aLink.click()
  document.body.removeChild(aLink)
}

export function copy(info, toast = '复制成功') {
  // #ifndef H5
  //uni.setClipboardData方法就是讲内容复制到粘贴板
  uni.setClipboardData({
    data: info, //要被复制的内容
    success: () => {
      //复制成功的回调函数
      uni.showToast({
        //提示
        title: toast,
        icon: 'none',
      })
    },
  })
  // #endif

  // #ifdef H5
  const textarea = document.createElement('textarea')
  textarea.value = info
  textarea.readOnly = 'readOnly'
  document.body.appendChild(textarea)
  textarea.select() // 选中文本内容
  textarea.setSelectionRange(0, info.length)
  uni.showToast({
    //提示
    title: toast,
    icon: 'none',
  })
  document.execCommand('copy')
  textarea.remove()
  // #endif
}

export function toLogin(params) {
  /* #ifdef H5 */
  return router.navigate('loginInput', params)
  /* #endif */
  /* #ifdef MP-WEIXIN */
  return router.navigate('login', params)
  /* #endif */
}

export function saveBase64Picture(base64) {
  const str = base64.split(',')[1] //去掉data:image/png;base64,只拿，号后面部分
  const bytes = atob(str)
  const ab = new ArrayBuffer(bytes.length)
  console.log(ab)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < bytes.length; i++) {
    ia[i] = bytes.charCodeAt(i)
  }
  const blob = new Blob([ab], { type: 'application/octet-stream' })
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = `${new Date().valueOf()}.jpg`
  const e = document.createEvent('MouseEvents')
  e.initMouseEvent(
    'click',
    true,
    false,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null,
  )
  a.dispatchEvent(e)
  URL.revokeObjectURL(url)
}

// h5保存图片
export function h5SaveImg(picUrl) {
  const a = document.createElement('a') //创建a 标签
  a.href = picUrl // 把图片路径赋到a标签的href上
  a.download = picUrl.replace(/(.*\/)*([^.]+.*)/gi, '$2').split('?')[0] // 图片文件名
  // 创建鼠标事件并初始化
  const e = new MouseEvent('click', (true, false, window))
  // 执行保存到本地
  a.dispatchEvent(e)
}

export function getFileFormat(val) {
  if (isImgFormat(val)) {
    return 'img'
  }
  if (isVideoFormat(val)) {
    return 'video'
  }
  if (isFileFormat(val)) {
    return 'file'
  }
  if (isAudioFormat(val)) {
    return 'audio'
  }
}

function isImgFormat(val) {
  const imgs = ['jpeg', 'jpg', 'png', 'heif', 'webp', 'gif']
  return imgs.includes(val)
}

function isVideoFormat(val) {
  const video = ['mp4', 'mov', 'avi', 'wmv']
  return video.includes(val)
}

function isFileFormat(val) {
  const file = ['docx', 'doc', 'ppt', 'pptx', 'xls', 'xlsx', 'pdf']
  return file.includes(val)
}

function isAudioFormat(val) {
  const file = ['mp3']
  return file.includes(val)
}

export function parseScene(scene) {
  const params = new Object()
  const sceneList = decodeURIComponent(scene).split('--') // &替换为--，切割参数
  for (let i = 0; i < sceneList.length; i++) {
    const val = sceneList[i].split('=')[1]
    const parseVal = val.replaceAll('++', '=') // 邀请人id加密后存在=，根据后端定义替换为++，这里重新替换还原=
    params[sceneList[i].split('=')[0]] = parseVal
  }
  return params
}
